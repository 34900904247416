<template>
  <div class="dopaper_card">
    <div class="top">
      <TopCard ref="TopCardRef"
               text="答题卡"
               :showBack="true" />
    </div>
    <div class="image_wrap">
      <p class="title">答题情况：</p>
      <div class="card_warp">
        <div :class="getClass(item)"
             v-for="(item,index) in paperList"
             @click="doPaper(index+1)"
             :key="index">
          <span v-if="checkTopic1.indexOf(item.topic) != -1||checkTopic.indexOf(item.topic) != -1">
            {{getAvlue(item,index+1)}}
          </span>
          <span v-else>
            <p>纸上作答</p>
            <p>上传图片</p>
          </span>
        </div>
      </div>

      <p class="title">请添加图片:<span>
          （每题最多可添加3张图片）</span></p>
      <div class="img_list">

        <div v-for="(item,index) in paperList"
             class="img_card"
             v-show="item.topic!='1'&&item.topic!='2'&&item.topic!='3'&&item.topic!='4'&&item.topic!='9'"
             :key="index">
          <!-- {{images}} -->
          <div style="font-size: 26px; width:50rem">{{index+1}} </div>
          <div class="img_wrap"
               v-for="(imgChild,indexChild,k) in images"
               v-if="item.answer_id == indexChild"
               :key="indexChild">
            <div v-if=" imgChild && imgChild.length>1 "
                 v-for="img,imgindex in imgChild"
                 :key="img">

              <img class="answer"
                   :src="$addAllHeader(img)"
                   alt="">
              <img class="del"
                   @click="del(item.answer_id,imgindex)"
                   src="@/assets/icons/删除.png"
                   alt="">
            </div>
            <div v-else>

              <img class="answer"
                   :src="$addAllHeader(img)"
                   alt="">
              <img class="
                   del"
                   @click="del(item.answer_id,imgindex)"
                   src="@/assets/icons/删除.png"
                   alt="">
            </div>

          </div>
          <div class="add_img"
               @click="uploadImage(item.answer_id)">
            <div>
              <img src="@/assets/add_img.png"
                   alt="">
            </div>
            点击上传
          </div>
        </div>
      </div>
    </div>

    <Hint ref="HintRef" />
    <div class="btn_warp">
      <Code ref="CodeRef" />
      <el-button class="fixed_bottom_btn"
                 @click="showDialog">
        提 交
      </el-button>
    </div>
  </div>
  </div>
</template>

<script>
import TopCard from '@/views/components/top_card/index.vue'
import { uploadFile, uploadImageAuto } from '@/api/upload.js'
import { submitUserPaper } from '@/api/paper_template.js'
import Hint from './components/hint.vue'
import Code from '@/views/components/code/index.vue'
export default {
  data () {
    return {
      images: {

      },
      paperList: [],
      checkTopic: [1, 2, 3, 4, 9],
      checkTopic1: ['1', '2', '3', '4', '9'],

    }
  },
  components: {
    TopCard, Hint, Code
  },
  // 离开前做处理
  beforeDestroy () {
    if (this.images.length) {
      window.sessionStorage.setItem('image', JSON.stringify(this.images))
    }
  },
  mounted () {
    let paperInfo = JSON.parse(window.sessionStorage.getItem('paper_info'))

    this.$refs.CodeRef.creatQrCode(paperInfo.user_paper_id)

    // 看情况处理 先用type做分类，可能不同做卷类型不一样
    this.type = this.$route.query.type
    if (this.type == 'group') {
      this.paperList = JSON.parse(window.sessionStorage.getItem('paper_list'))
    }
    if (window.sessionStorage.getItem('image')) {
      this.images = JSON.parse(window.sessionStorage.getItem('image'))
    }

  },
  methods: {

    del (answer_id, index) {
      if (this.images.hasOwnProperty(answer_id)) { // 检查对象中是否存在指定的answer_id键
        let imageArray = this.images[answer_id]; // 获取指定answer_id键对应的数组
        if (index >= 0 && index < imageArray.length) { // 检查索引是否在合法范围内
          imageArray.splice(index, 1); // 使用splice函数从数组中删除指定索引的元素
        }
      }
    },

    async uploadImage (answer_id) {

      if (this.images[answer_id] && this.images[answer_id].length == 3) {
        this.$message.info("每题最多可添加3张图片")
        return
      }


      const { data } = await uploadImageAuto()
      const newImage = {
        [answer_id]: [
          this.$addAllHeader(data.info.name)
        ]
      }
      if (this.images[answer_id]) {
        this.images[answer_id].push(this.$addAllHeader(data.info.name))
      }
      else {
        this.images = Object.assign({}, this.images, newImage);
      }
      this.$message.success('上传成功')
    },
    getClass (item) {
      var str = ''
      // 单选题 topic
      if ((item.topic == 1 || item.topic == 9) && item.answer.length) {
        str = 'one_active'
      }
      // 纸上作答   当上传图片之后 高亮
      if (this.checkTopic.indexOf(item.topic) == -1 && this.images.length) {
        str = 'one_active'
      }
      if (item.answer.length) {
        str = 'one_active'
      }
      return str + ' card'
    },
    getAvlue (item, index) {
      console.log(item)
      if (item.topic == 1 || item.topic == 9 || item.topic == 2 || item.topic == 3 || item.topic == 4) {
        if (item.answer.length) {
          let arr = item.answer.slice().sort()
          return arr.join('')
        } else {
          return index
        }
      }
      return index
    },
    doPaper (index) {
      window.sessionStorage.setItem('topic_index', index)
      window.sessionStorage.setItem('image', JSON.stringify(this.images))
      this.$router.go(-1)
    },
    async submitPaper () {
      let paper_info = JSON.parse(window.sessionStorage.getItem('paper_info'))
      let form = {
        user_paper_id: paper_info.user_paper_id,
        chs_answer: {},
        other_answer: this.images
      }
      this.paperList.forEach(item => {
        // form.chs_answer[item.answer_id] = item.answer.join(',')
        if (item.answer && item.answer.length) {
          form.chs_answer[item.answer_id] = item.answer.join(',')
        }
      })
      form.other_answer = JSON.stringify(form.other_answer)
      await submitUserPaper(form)
      this.$message.success('提交成功!可前往我的试卷查看')
      this.$router.go(-2)
      this.$removePaperInfo()
    },
    showDialog () {
      var num = 0;
      this.paperList.forEach(item => {
        var idx = this.$checkArr.findIndex(topic => topic == item.topic)
        if ((idx != -1 && item.answer.length == 0) || (idx == -1 && this.images.length == 0)) {
          num += 1;
        }
      })
      this.$refs.HintRef.num = num
      this.$refs.HintRef.dialogVisible = true
    }

  }
}


</script>


<style lang="scss" scoped>
.dopaper_card {
  overflow: hidden;
  .top {
    width: 100vw;
    height: 100rem;
  }
  .title {
    text-indent: 40rem;
    font-size: 20rem;
    font-weight: bold;
    color: #333333;
    span {
      color: #848484;
    }
  }
  .card_warp {
    // width: calc(100vw - 46rem);
    margin: 20rem 6rem 10rem 40rem;
    justify-content: flex-start;
    flex-wrap: wrap;
    display: flex;
    // max-height: 450rem;
    overflow-y: auto;
    .card {
      width: 120rem;
      height: 120rem;
      border-radius: 10rem;
      display: flex;
      justify-content: center;
      align-items: center;
      background: #f6f6f6;
      margin-right: 24rem;
      margin-bottom: 30rem;
      font-size: 16rem;
    }
  }
  .img_list {
    margin-top: 30rem;
    margin-left: 40rem;
    display: flex;
    flex-wrap: wrap;
    .img_card {
      display: flex;
      width: 610rem;
      margin-top: 10px;
    }
    .img_wrap {
      position: relative;
      // margin-right: 24rem;
      display: flex;
      // width: 120rem;
      height: 120rem;
      .del {
        position: relative;
        right: 15rem;
        top: -9rem;
        width: 30rem;
        height: 30rem;
        cursor: pointer;
      }
      .answer {
        border-radius: 10rem;
        width: 116rem;
        height: 120rem;
      }
    }
    .add_img {
      width: 120rem;
      height: 120rem;
      background: #f6f6f6;
      border-radius: 10rem;
      font-size: 20rem;
      font-weight: 500;
      color: #2196f3;
      text-align: center;
      cursor: pointer;
      img {
        width: 40rem;
        height: 34rem;
        margin-top: 26rem;
        margin-bottom: 6rem;
      }
    }
  }
  .btn_warp {
    // position: fixed;
    // bottom: 26rem;
    display: flex;
    align-items: flex-end;
    margin-bottom: 28rem;
    .fixed_bottom_btn {
      width: 300rem;
      margin-left: 180rem;
      font-size: 20rem;
      font-weight: bold;
      color: #ffffff;
      height: 60rem;
      background: #2196f3;
      border-radius: 10rem;
      justify-content: center;
      align-items: center;
    }
  }
}

.one_active {
  background: #ecf5fc !important;
  color: #2196f3 !important;
  font-weight: bold !important;
}
.image_wrap {
  // height: calc(100vh - 100rem);
  overflow-x: hidden;
  overflow-y: auto;
}
</style>